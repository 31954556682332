

.header{
  background: $main-color;
  nav{
    z-index: 999;
    transition: all .5s ease-out;
    &.minified{
      box-shadow: 0px 0px .25rem black;
      padding: .25rem 2rem !important;
      .navbar-brand{
        width: 3rem;
        z-index: -9;
        transition: width .5s .5s ease-in;
        &__text{
          opacity: 0;
          width: 0 !important;
        }
      }
      .btn{
        padding: .2rem .5rem;
        font-size: .8rem;
      }
    }
    .navbar-brand{
      @include media-breakpoint-up(sm) {
        width: 16.5rem;
        transition: width .25s ease-in;
      }
      &__text{
        opacity: 1;
        transition: opacity .5s ease-in-out;
      }
    }
  }
  .navbar.bg-dark{
    background-color: $main-color !important;
  }
  .nav-item > .active {
    border-bottom: 1px solid #333;
  }
  .navbar-brand{
    font-size: 1rem;
    margin-right: .25rem;
    @include media-breakpoint-up(sm) {
      font-size: 1.25rem;
    }
  }
  .navbar-toggler{
    padding: 0.15rem 0.35rem;
    font-size: 1rem;
  }
  .navbar-collapse{
    padding-bottom: 1rem;
  }
  &__content{
    position: absolute;
    top: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
    width: 100%;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    z-index: 99;
    @include media-breakpoint-up(lg) {
      height: 80vh;
    }
    h1{
      font-weight: 900;
      @include media-breakpoint-up(sm) {
        font-size: 4rem;
        line-height: 3.5rem;
      }
    }
    small{
      display: block;
      font-size: 70%;
      letter-spacing: 0.53rem;
      @include media-breakpoint-up(sm) {
        letter-spacing: .89rem;
      }
    }
    p{
      font-size: 1.2rem;
      font-weight: 200;
      @include media-breakpoint-up(sm) {
        font-size: 1.5rem;
      }
    }
  }
}

.background{
  position: relative;
  overflow: hidden;
  &__video{
    z-index: -99;
    object-fit: cover;
    width: 100vw;
    height: 65vh;
    filter: brightness(0.6);
    @include media-breakpoint-up(lg) {
      height: 80vh;
    }
  }
  &__svg{
    width: 100%;
    height: -webkit-fill-available;
    position: absolute;
    bottom: .3rem;
    z-index: 99;
    left: 0;
    path{
      fill: rgba(6,35,72,.5);
    }
  }
}
