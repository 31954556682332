$main-color: #062348;
$accent-color: #f86124;
$accent-color-2: #a5a6a5;
$text-color: #f8f8ff;

body{
  background: $text-color;
  font-family: 'Nunito Sans', sans-serif;
  &::-webkit-scrollbar {
    width: .75rem;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $accent-color-2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $accent-color;
    outline: 1px solid $text-color;
    border: 1px solid $accent-color;
  }
}

a:not(.nav-link){
  color: $accent-color;
  text-decoration: none;
  &.btn{
    border-color: $accent-color;
    &:hover{
      background: $accent-color;
      color: $text-color;
    }
  }
  &:hover{
    color: darken($accent-color,10);
  }
}

.cursor-pointer{
  cursor: pointer;
}

.fw-600{
  font-weight: 600;
}

.btn-link{
  padding: 0;
  &:focus{
    box-shadow: none;
  }
  &:hover{
    text-decoration: none;
  }
}

.list-group-item.active{
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}
.services__list .list-group-item{
  background-color: $accent-color;
  color: $text-color;
  border-radius: 1rem;
  &:hover, &:focus{
    background-color: lighten($accent-color, 2);
    color: $text-color;
  }
}

.modal-content{
  border: 0;
  border-radius: 1rem;
  padding: 1rem 3rem;
  .about-photo{
    width: 250px;
  }
}
.modal-header, .modal-footer{
  background-color: white;
  color: $main-color;
  border-radius: 1rem;
  .close{
    color: $main-color;
    font-size: 2.3rem;
    position: absolute;
    right: 2rem;
    top: 1rem;
  }
}

.covid-update{
  position: fixed;
  bottom: 7rem;
  right: 1.7rem;
  cursor: pointer;
  filter: drop-shadow(0px 0px 2px $accent-color-2);
  svg[data-icon='circle']{
    transition: all .5s ease-in-out;
  }
  &:hover{
    svg[data-icon='circle']{
      color: $accent-color;
    }
  }
}
