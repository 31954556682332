.section{
  padding-left: 0;
  padding-right: 0;
  @include media-breakpoint-between(sm, md) {
    padding-left: calc((100vw - 540px) / 2);
    padding-right: calc((100vw - 540px) / 2);
  }
  @include media-breakpoint-between(md, lg) {
    padding-left: calc((100vw - 720px) / 2);
    padding-right: calc((100vw - 720px) / 2);
  }
  @include media-breakpoint-between(lg, xl) {
    padding-left: calc((100vw - 960px) / 2);
    padding-right: calc((100vw - 960px) / 2);
  }
  @include media-breakpoint-up(xl) {
    padding-left: calc((100vw - 1140px) / 2);
    padding-right: calc((100vw - 1140px) / 2);
  }
}

.dark-section{
  background: $main-color;
  color: $text-color;
}
.light-section{
  background: $text-color;
  color: $main-color;
}

#summary{
  img{
    height: 10rem;
  }
}

#who-we-are{
  h2{
    font-weight: 700;
    font-size: 2.5rem;
  }
}

#services{
  h2{
    font-weight: 700;
    font-size: 2.5rem;
  }
  clip-path: polygon(0 3%, 100% 0, 100% 90%, 0 93%);
  margin-top: -3rem;
  padding-top: 3rem;
  padding-bottom: 8rem;
  @include media-breakpoint-up(sm) {
    clip-path: polygon(0 5%, 100% 0, 100% 90%, 0 95%);
    margin-top: -4rem;
    padding-top: 5rem;
    padding-bottom: 8rem;
  }
  @include media-breakpoint-up(md) {
    clip-path: polygon(0 6%, 100% 0, 100% 90%, 0 97%);
    margin-top: -3rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
  }
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
    margin-top: -1.5rem;
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
  @include media-breakpoint-up(xl) {
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
    margin-top: -3rem;
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
  div:nth-child(3){
    width: 100%;
  }
}

#about{
  margin-top: -1rem;
  h2{
    font-weight: 700;
    font-size: 2.5rem;
  }
  h3{
    width: 10rem;
  }
  .about__image-container{
    transition: filter .3s ease-in;
    min-height: 220px;
  }
  .about__image{
    border-radius: .5rem;
    box-shadow: 0 5px 5px rgb(0 0 0 / 0.2);
    width: 10rem;
  }
  .about__email{
    color: $main-color;
  }

}

.footer{
  h5{
    font-weight: 700;
    letter-spacing: .06rem;
  }
  &__bottom{
    font-size: .75rem;
  }
  .list-group-item{
    background: $text-color;
    border-radius: 1rem;
  }
  .btn{
    border: none;
  }
}
